import { FC } from 'react'
import { SvgProps } from './svgProps'

const SearchIcon: FC<SvgProps> = ({ width, height, color, ...rest }) => (
  <svg
    width={width || 25}
    height={height || 25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.185 15.1478C16.2297 14.0938 16.875 12.643 16.875 11.0417C16.875 7.82001 14.2633 5.20834 11.0416 5.20834C7.81999 5.20834 5.20831 7.82001 5.20831 11.0417C5.20831 14.2633 7.81999 16.875 11.0416 16.875C12.662 16.875 14.1279 16.2144 15.185 15.1478ZM15.185 15.1478L19.7916 19.7917M8.85415 8.85417L13.0208 13.0208M13.0208 8.85417L8.85415 13.0208"
      stroke={color || '#757575'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SearchIcon
