import { FC, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { Routes } from '@/core/routes'
import { SearchIconNewDefault } from '../atoms/icons/SearchIconDefaultNew'
import { ClearIconDefault } from '../atoms/icons/ClearIconDefault'
import ProductSearchPopUpList from './productSearch/productSearchPopUpList'
import { ArrowIconBreadcrumbnDefault } from '../atoms/icons/ArrowIconBreadcrumb'
import { useOverlay } from '@/providers/showOverlayNewProvider'

type ProductSearchNewProps = {
  isSearchbarOpen?: boolean
} & Rest

const ProductSearchNew: FC<ProductSearchNewProps> = ({
  isSearchbarOpen,
  ...rest
}) => {
  const isHovered = useRef(false)
  const inputRef = useRef(null)
  const router = useRouter()
  const { t } = useTranslation('common')
  const popupRef = useRef<HTMLDivElement>(null)
  const { showOverlayNew, setShowOverlayNew } = useOverlay()

  useEffect(() => {
    if (inputRef) {
      inputRef.current.value = router.query?.search ?? ''
    }
  }, [router.query?.search])

  const submitSearch = (e: React.FormEvent) => {
    e.preventDefault()

    if (!inputRef.current.value) {
      inputRef.current.focus()
      return
    }

    router.push({
      pathname: Routes.search,
      query: { search: inputRef.current.value }
    })

    setQuery('')
    if (inputRef.current) {
      inputRef.current.value = ''
    }

    setShowOverlayNew(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node) &&
        !popupRef.current?.contains(event.target as Node)
      ) {
        setShowOverlayNew(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleHover = (isHover: boolean) => {
    isHovered.current = isHover
  }

  const [query, setQuery] = useState<string>('')

  const clearInput = () => {
    setQuery('')
    inputRef.current?.focus()
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }

  return (
    <>
      <StyledForm onSubmit={submitSearch} key={router.asPath}>
        <StyledContainer showOverlayNew={showOverlayNew}>
          {showOverlayNew && (
            <ArrowIconWrapper>
              <ArrowIconBreadcrumbnDefault />
            </ArrowIconWrapper>
          )}
          <StyledSearchWrapper
            showOverlayNew={showOverlayNew}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
            {...rest}
          >
            {!showOverlayNew && (
              <StyledSearchButtonNew
                type="button"
                onClick={() => inputRef.current?.focus()}
              >
                <SearchIconNewDefault />
              </StyledSearchButtonNew>
            )}

            <StyledSearchInputNew
              ref={inputRef}
              id="autocompleteInput"
              type="text"
              name="ProductSearchNew"
              placeholder={t('Toolbar.Search.inputPlaceholderNew')}
              data-cy="headerInputSearch"
              className={'fulltext-autocomplete'}
              isSearchbarOpen={isSearchbarOpen}
              value={query}
              onChange={handleInputChange}
              onFocus={() => setShowOverlayNew(true)}
            />
            {query && (
              <StyledClearButton type="button" onClick={clearInput}>
                <ClearIconDefault />
              </StyledClearButton>
            )}
          </StyledSearchWrapper>
        </StyledContainer>

        {showOverlayNew && (
          <div ref={popupRef}>
            <ProductSearchPopUpList
              onLinkClick={() => setShowOverlayNew(false)}
              listType="searchTerms"
            />
          </div>
        )}
      </StyledForm>
    </>
  )
}

export default ProductSearchNew

const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
`

const StyledContainer = styled.div<{ showOverlayNew: boolean }>`
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.showOverlayNew ? '20px' : '0')};
`

const StyledSearchWrapper = styled.div<{ showOverlayNew?: boolean }>`
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  border-radius: 10px;
  padding: 5px 0 5px 15px;
  min-width: 0;
  margin: ${(props) => (props.showOverlayNew ? '0 20px 10px 10px' : '0 15px')};
  flex-grow: 1;
  width: 100%;
`

const StyledForm = styled.form`
  width: 100%;
  position: relative;
`

const StyledClearButton = styled.div`
  z-index: 3;
  width: 36px;
  border-radius: 50%;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`

const StyledSearchInputNew = styled.input`
  flex-grow: 1;
  border: none !important;
  background: transparent;
  outline: none;
  padding: 8px;
  font-size: 12px;

  padding: 8px 8px 8px 0;
  z-index: 3;
  ${(props) => props.theme.headerSearchInput && props.theme.headerSearchInput}

  &::placeholder {
    color: #757575;
  }
  &::-ms-input-placeholder {
    color: ${(props) => props.theme.colors.searchInputColor};
  }
  &:focus {
    outline-style: none;
    border: ${(props) => props.isSearchbarOpen && 'none'};
  }
`

const StyledSearchButtonNew = styled.button`
  background: none;

  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  & svg {
    fill: ${(props) => props.theme.colors.searchButton};
    width: 20px;
    height: 20px;
  }
  &:hover svg {
    fill: ${(props) => props.theme.colors.searchInputColor};
  }
`
