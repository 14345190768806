import { FC } from 'react'
import { SvgProps } from './svgProps'

const SearchIcon: FC<SvgProps> = ({ width, height, color, ...rest }) => (
  <svg
    width={width || 25}
    height={height || 25}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M15.9767 15.9395C17.0214 14.8854 17.6667 13.4347 17.6667 11.8333C17.6667 8.61168 15.055 6 11.8333 6C8.61168 6 6 8.61168 6 11.8333C6 15.055 8.61168 17.6667 11.8333 17.6667C13.4536 17.6667 14.9196 17.006 15.9767 15.9395ZM15.9767 15.9395L20.5833 20.5833M11.7292 14.8542V10.6875M11.7292 9.125V8.60417"
      stroke={color || '#757575'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SearchIcon
