import { FC } from 'react'
import { SvgProps } from './svgProps'

const SearchIcon: FC<SvgProps> = ({ width, height, color, ...rest }) => (
  <svg
    width={width || 22}
    height={height || 20}
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M3.66666 10H18.3333M18.3333 10L12.8333 15M18.3333 10L12.8333 5"
      stroke={color || '#757575'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SearchIcon
