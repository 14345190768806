import { FC } from 'react'
import { SvgProps } from './svgProps'

const WishlistIcon: FC<SvgProps> = ({ width, height, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 20}
    height={height || 20}
    viewBox="0 0 17 17"
    {...rest}
  >
    <use href="#wishlist-icon-definition" stroke={color || '#595959'} />
  </svg>
)

export default WishlistIcon
