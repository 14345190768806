import type { FC } from 'react'
import { memo, useContext, useEffect, useMemo, useState } from 'react'
import { Box, Flex } from '@/components/atoms/Grid'
import styled, { css } from 'styled-components'
import { Config } from '@/core/config'
import { validFor } from '@/theme/validFor'
import { FocusMode, useCurrentPage } from '@/providers/focusModeProvider'
import {
  isOrderPage,
  useOrderDispatch
} from '@/providers/order/old/orderDispatchProvider'
import type {
  GetClientDataQuery,
  HeaderNotice,
  Market,
  Sdk
} from '@/network/graphql.g'
import { DeviceType, MarketCode, NavigationItem } from '@/network/graphql.g'
import useSWR from 'swr'
import { useUser } from '@/providers/userProvider'
import { useRouter } from 'next/router'
import useGraphQl from 'hooks/useGraphQl'
import { getSessionId } from '@/utils/sessionService'
import { useWishlist } from '@/providers/wishlistProvider'
import { getLocalWishlistIds } from '@/providers/wishlistDispatchProvider'
import { getShallowParams } from '@/utils/routerHelpers'
import { useOrder } from '@/providers/order/old/orderProvider'
import { useMhub } from '@/providers/mhubProvider'
import { LoginType } from '@/providers/mhub/userManager'
import dynamic from 'next/dynamic'
import objectHash from '@/core/crypto/objectHash'
import useDevice from 'hooks/useDevice'
import {
  useMainNavigation,
  useMainNavigationDispatch
} from '@/providers/mainNavigationMenuProvider'
import { useCookie } from 'next-cookie'
import { StoreContext, useDomain } from '@/providers/storeProvider'

import { useAsset } from '@/providers/assetsProvider'
import DesktopCategoryMenu from '@/components/organisms/desktopCategoryMenu'
import { usePremiumManufacturersNavigation } from '@/providers/premiumManufacturersMenuProvider'
import IconsDefinitionList from '@/components/atoms/svg/iconsDefinitions/IconsDefinitionList'
import IconsDefinitionListFrogies from '@/components/atoms/svg/iconsDefinitions/IconsDefinitionListFrogies'
import { useTowerCheckout } from '@/providers/experimentProvider'

const DynamicHeader = dynamic(() => import('./header'))
const DynamicFooter = dynamic(() => import('@/components/organisms/footer'), {
  ssr: false
})
const DynamicHeaderPromo = dynamic(
  () => import('@/components/molecules/headerPromo/headerPromo')
)

const DynamicAboutUsPopupEU = dynamic(
  () => import('@/components/organisms/aboutUsPopupEU')
)

type ContainerProps = {
  facebookRegistration: boolean
}

const Container: FC<ContainerProps> = memo(
  ({ facebookRegistration, children }) => {
    const { reloadOrder, abortOrderReload } = useOrderDispatch()
    const { getOrderChecksum, createOrderChecksum } = useOrder()
    const {
      isLoggedInBySession,
      updateUser,
      user,
      getUserChecksum,
      isLoggedIn
    } = useUser()
    const router = useRouter()
    const { pushLoginEvent, pushRegisterEvent, pushCustomerFromMailing } =
      useMhub()
    const { isFrogies } = useContext(StoreContext)
    const { setMainNavigation } = useMainNavigationDispatch()
    const [markets, setMarkets] = useState<Market[]>([])
    const [headerNotice, setHeaderNotice] = useState<HeaderNotice>()
    const { request } = useGraphQl()
    const { setWishlistProductCount } = useWishlist()
    const { getWishlistChecksum, createWishlistChecksum, setWishlist } =
      useWishlist()
    const { isTablet, isDesktop } = useDevice()
    const [showHeaderPromo, setShowHeaderPromo] = useState<boolean>(true)
    const { subdomainName } = useDomain()
    const cookie = useCookie()
    const cookieAboutUsPopupEUExists = cookie.get('aboutUsPopupEU')
    const [showAboutUsPopupEU, setShowAboutUsPopupEU] = useState<boolean>(false)
    const isShowingAboutUsPopup =
      showAboutUsPopupEU && subdomainName === MarketCode.Ua

    const { isClusterVolumeAboutUsPopup } = useAsset()
    const isShowingClusterVolumeAboutUsPopup =
      showAboutUsPopupEU && isClusterVolumeAboutUsPopup

    useEffect(() => {
      if (!cookieAboutUsPopupEUExists) {
        setShowAboutUsPopupEU(true)
      }
    }, [cookieAboutUsPopupEUExists])

    const deviceType = isDesktop
      ? DeviceType.Desktop
      : isTablet
      ? DeviceType.Tablet
      : DeviceType.Mobile

    const loadMinorData = async () => {
      const ssrUrlTopMenu = router.asPath.replace(/\d+/g, '')
      const withMainNavigation = ssrUrlTopMenu === '/product/'
      const wishlistChanged = createWishlistChecksum() !== getWishlistChecksum()
      const customerChanged = objectHash(user ?? null) !== getUserChecksum()
      const { success, response } = await request(
        (sdk: Sdk) => ({
          method: sdk.getMinorData,
          variables: {
            withMainNavigation,
            withMarkets: true,
            withCustomerOrLoggedInWishlist:
              customerChanged || (wishlistChanged && isLoggedInBySession()),
            withLoggedInWishlist: wishlistChanged && isLoggedInBySession(),
            device: deviceType,
            ids: getLocalWishlistIds() ?? [],
            withLoggedOutWishlist: wishlistChanged && !isLoggedInBySession()
          },
          requestHeaders: isLoggedIn
            ? {}
            : {
                'x-cachable': '1'
              }
        }),
        true
      )
      if (success) {
        if (withMainNavigation) {
          setMainNavigation(
            JSON.parse(response?.mainNavigationJson) as NavigationItem
          )
        }
        setMarkets(response?.markets)
      }
      if (success) {
        setHeaderNotice(response?.headerNotices)
        if (wishlistChanged) {
          setWishlist(
            isLoggedInBySession()
              ? response?.activeCustomer?.wishlistProducts
              : response?.loggedOutWishlist
          )
          setWishlistProductCount(
            isLoggedInBySession() &&
              response?.activeCustomer?.wishlistProductsCount
          )
        }
      }
    }

    const getCustomerFromMailingData = async (mailingId: string) => {
      const { response } = await request(
        (sdk: Sdk) => ({
          method: sdk.getCustomerFromMailingData,
          variables: {
            mailingId
          }
        }),
        true
      )
      return response
    }

    const isCustomerFromMail =
      router.query?.utm_term &&
      router.query?.utm_medium === 'email' &&
      !isLoggedIn

    const maybeOptionsKey = isCustomerFromMail ? router.query?.utm_term : null

    const { data: customerFromMailing } = useSWR(
      maybeOptionsKey,
      getCustomerFromMailingData,
      {
        revalidateOnFocus: false,
        revalidateOnReconnect: true
      }
    )

    useEffect(() => {
      if (customerFromMailing) {
        pushCustomerFromMailing(
          LoginType.Newsletter,
          customerFromMailing.getCustomerFromMailingData
        )
      }
    }, [customerFromMailing])

    useEffect(() => {
      facebookRegistration && pushRegisterEvent(LoginType.Facebook)

      if (router.query?.loginSuccess) {
        pushLoginEvent(router.query?.loginSuccess as LoginType)
        delete router.query.loginSuccess
        const { url, as } = getShallowParams(router)
        router.replace(url, as, { shallow: true })
      }
      loadMinorData()
    }, [])

    const { getFocusType } = useCurrentPage()
    const isFullFocus = getFocusType() === FocusMode.Full

    const clientDataFetcher = async (
      _key,
      _isLoggedIn,
      path
    ): Promise<GetClientDataQuery> => {
      const customerChanged =
        objectHash(user ?? null) !== getUserChecksum() || isLoggedInBySession()
      const cartChanged = createOrderChecksum() !== getOrderChecksum()
      const wishlistChanged = createWishlistChecksum() !== getWishlistChecksum()
      let result: GetClientDataQuery

      if (cartChanged || customerChanged) {
        const abortSignal = abortOrderReload()
        const orderPage = isOrderPage(path)
        const { success, response } = await request(
          (sdk: Sdk) => ({
            method: sdk.getClientData,
            variables: {
              withCart: cartChanged,
              withCustomer: customerChanged,
              withLoggedInWishlist: wishlistChanged && isLoggedInBySession(),
              withLoggedOutWishlist: wishlistChanged && !isLoggedInBySession(),
              withCustomerOrLoggedInWishlist:
                customerChanged || (wishlistChanged && isLoggedInBySession()),
              sessionId: getSessionId(),
              withMHubOrder: orderPage,
              checkQuantity: orderPage,
              ids: getLocalWishlistIds() ?? [],
              ipAddress: '',
              wasInAdmin: false,
              device: isDesktop
                ? DeviceType.Desktop
                : isTablet
                ? DeviceType.Tablet
                : DeviceType.Mobile
            }
          }),
          true,
          null,
          abortSignal
        )
        if (success) {
          if (customerChanged) {
            updateUser(response?.activeCustomer, false)
          }
          if (cartChanged) {
            reloadOrder(response?.getCart)
          }
          result = response
        }
      }
      return result
    }

    useSWR(
      ['clientData', isLoggedInBySession(), router.pathname],
      clientDataFetcher
    )

    const mainNavigation = useMainNavigation()
    const otherMenuItems = useMemo(
      () =>
        mainNavigation?.children.filter(
          ({ isMainCategory }) => !isMainCategory
        ),
      [mainNavigation]
    )
    const navigationItems = useMemo(
      () =>
        mainNavigation?.children.filter(({ isMainCategory }) => isMainCategory),
      [mainNavigation]
    )
    const premiumManufacturersMainNavigation =
      usePremiumManufacturersNavigation()

    const isFocus = getFocusType() !== FocusMode.None
    const { isShutDownCountry, isCartProcessStep } = useAsset()
    const isTowerCheckout = useTowerCheckout()

    return (
      <Wrapper
        flexDirection="column"
        alignItems="stretch"
        suppressHydrationWarning
      >
        {!isFullFocus && (
          <>
            <StyleDynamicHeaderPromo
              data-cy="headerPromo"
              headerNotice={headerNotice}
              showHeaderPromo={showHeaderPromo}
              setShowHeaderPromo={setShowHeaderPromo}
            />

            <StyleDynamicHeader
              markets={markets}
              data-cy="headerMenu"
              showHeaderPromo={showHeaderPromo}
              headerNotice={headerNotice}
              navigationItems={navigationItems}
              otherMenuItems={otherMenuItems}
              premiumManufacturers={premiumManufacturersMainNavigation}
            />
          </>
        )}
        {!isFocus && !isShutDownCountry && (
          <StyledBox
            suppressHydrationWarning
            display={{ desktop: 'block', tablet: 'none', mobile: 'none' }}
          >
            <DesktopCategoryMenu
              navigationItems={navigationItems}
              otherMenuItems={otherMenuItems}
              premiumManufacturers={premiumManufacturersMainNavigation}
            />
          </StyledBox>
        )}
        <StyledMain>
          {(isShowingAboutUsPopup || isShowingClusterVolumeAboutUsPopup) && (
            <DynamicAboutUsPopupEU
              setShowAboutUsPopupEU={setShowAboutUsPopupEU}
              showAboutUsPopupEU={showAboutUsPopupEU}
            />
          )}
          {isFrogies ? (
            <IconsDefinitionListFrogies />
          ) : (
            <>
              <IconsDefinitionListFrogies />
              <IconsDefinitionList />
            </>
          )}

          <ContentWrapper>{children}</ContentWrapper>
        </StyledMain>
        <StyledFooter>
          {!isCartProcessStep || !isTowerCheckout ? (
            <DynamicFooter markets={markets} />
          ) : null}
        </StyledFooter>
      </Wrapper>
    )
  }
)

export default Container

const StyleDynamicHeader = styled(DynamicHeader)`
  flex-shrink: 0;
  top: -1px;
  z-index: 90;
  min-height: 50px;
  position: sticky;
  ${validFor.desktop`
    position: relative;
  `}
`

const StyleDynamicHeaderPromo = styled(DynamicHeaderPromo)`
  flex-shrink: 0;
  z-index: 90;
  position: relative;
`

const StyledBox = styled(Box)`
  position: sticky;
  top: 0;
  z-index: 89;
`

const StyledMain = styled.div`
  flex-shrink: 0;
`

const StyledFooter = styled.div`
  flex-shrink: 0;
`

const Wrapper = styled(Flex)`
  min-height: 100%;
  ${StyledMain} {
    flex-grow: 1;
  }
`
const ContainerCss = css`
  margin: auto;
  ${validFor.wide`
    max-width: ${Config.wideScreenPageWidth}px;
  `}
`

export const ContentWrapper = styled(Box)`
  ${ContainerCss};
`
