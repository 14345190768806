import styled from 'styled-components'
import { FC } from 'react'

import { MediaContextProvider } from '@/components/atoms/media'
import SearchInfoIcon from '@/components/atoms/svg/SearchInfoIcon'
import SearchNotFoundIcon from '@/components/atoms/svg/SearchNotFoundIcon'
import ArrowRightIcon from '@/components/atoms/svg/ArrowRightIcon'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { Routes } from '@/core/routes'

type ListType = 'searchTerms' | 'searchTermsLocalStorage' | 'notFound'

interface ListData {
  leftIcon: JSX.Element // Ikona vľavo (spoločná pre celý zoznam)
  rightIcon?: JSX.Element // Ikona vpravo (spoločná pre celý zoznam)
  items: { title: string }[] // Pole položiek s názvami
}

interface ProductSearchPopUpListProps {
  listType: ListType
  onLinkClick: () => void
}

const ProductSearchPopUpList: FC<ProductSearchPopUpListProps> = ({
  listType,
  onLinkClick
}) => {
  const { t } = useTranslation('common')

  const defaultData: Record<ListType, ListData> = {
    searchTerms: {
      leftIcon: <SearchInfoIcon color="#8f8f8f" />,
      rightIcon: <ArrowRightIcon color="#000" />,
      items: [
        { title: t('Toolbar.Search.searchTerm1') },
        { title: t('Toolbar.Search.searchTerm2') },
        { title: t('Toolbar.Search.searchTerm3') }
      ]
    },
    searchTermsLocalStorage: {
      leftIcon: <SearchInfoIcon color="#8f8f8f" />,
      rightIcon: <ArrowRightIcon color="#000" />,
      items: []
    },
    notFound: {
      leftIcon: <SearchNotFoundIcon color="#8f8f8f" />,
      items: [{ title: t('Toolbar.Search.searchTermNotFound') }]
    }
  }

  const data = defaultData[listType]

  const { leftIcon, rightIcon, items } = data

  const limitString = (text: string, maxCharacters: number): string => {
    if (!text) {
      return ''
    }
    if (text.length <= maxCharacters) {
      return text
    } else {
      return text.substring(0, maxCharacters) + '...'
    }
  }

  return (
    <MediaContextProvider>
      <ModalWrapper>
        <ListWrapper>
          {items.map((item, index) => (
            <div key={index}>
              <ListItem>
                <LeftIconWrapper>{leftIcon}</LeftIconWrapper>
                <Link
                  href={{
                    pathname: Routes.search,
                    query: { search: item.title }
                  }}
                >
                  <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    onClick={(e) => {
                      onLinkClick()
                    }}
                  >
                    <Text>{limitString(item?.title, 43)}</Text>
                  </a>
                </Link>

                {rightIcon && <RightIconWrapper>{rightIcon}</RightIconWrapper>}
              </ListItem>
              {index < items.length && <Divider />}
            </div>
          ))}
        </ListWrapper>
      </ModalWrapper>
    </MediaContextProvider>
  )
}

export default ProductSearchPopUpList

const ModalWrapper = styled.div`
  display: flex;
  background-color: white;
  z-index: 1001;
  padding: 10px 0px;
  position: relative;
  top: 10px;
  height: 100vh;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
`

const ListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`

const Text = styled.span`
  margin-left: 20px;
  font-size: 12px;
  color: #757575;
  flex: 1;
`

const RightIconWrapper = styled.div`
  margin-left: auto;
  padding: 0px 20px 0px 0px;
`

const LeftIconWrapper = styled.div`
  // margin-left: auto;
  padding: 0px 0px 0px 20px;
`

const Divider = styled.hr`
  width: 100%;
  border: 0;
  border-top: 1px solid #eeeeee;
  margin: 0;
`
