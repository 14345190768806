import MainNavigationProvider from './mainNavigationMenuProvider'
import React from 'react'
import WishlistProvider from '@/providers/wishlistProvider'
import { CurrentPageProvider } from '@/providers/focusModeProvider'
import { CartModel } from '@/network/models/cartModel'
import UserProvider from '@/providers/userProvider'
import { CurrencyContext } from './currencyProvider'
import {
  Currency,
  Customer,
  Experiment,
  NavigationItem,
  Order,
  PremiumManufacturer,
  SeoTags,
  Store
} from '@/network/graphql.g'
import ExternalRoutesProvider from './externalRoutesProvider'
import LoadingProvider from './loadingProvider'
import MessageProvider from './messageProvider'
import OrderProvider from './order/old/orderProvider'
import { LuigiTrackerProvider } from './luigiTrackerProvider'
import { FacebookProvider } from './facebookProvider'
import { Session } from '@/core/auth/session'
import AuthProvider from './authProvider'
import ExperimentProvider from './experimentProvider'
import AdditionalInfoProvider from './additionalInfoProvider'
import MhubProvider, { isMhubEnabled } from './mhubProvider'
import OrderDispatchProvider from './/order/old/orderDispatchProvider'
import WishlistDispatchProvider from './wishlistDispatchProvider'
import PremiumManufacturersNavigationProvider from './premiumManufacturersMenuProvider'
import { OverlayProvider } from './showOverlayNewProvider'

const ProviderComposer = ({ contexts, children }) => {
  return contexts.reduceRight(
    (kids, parent) =>
      React.cloneElement(parent, {
        children: kids
      }),
    children
  )
}

type RootProviderProps = {
  navigationItem: NavigationItem
  premiumManufacturers: PremiumManufacturer[]
  currentPathName: string
  cartModel?: CartModel
  currency: Currency
  store: Store
  ip: string
  serverTime: number
  order?: Order
  user: Customer
  referer: string
  session: Session
  runningUserExperiments: string
  runningExperiments: Experiment[]
  seoTags: SeoTags
}

const RootProvider: React.FC<RootProviderProps> = ({
  navigationItem,
  premiumManufacturers,
  currentPathName,
  children,
  currency,
  store,
  ip,
  serverTime,
  order,
  user,
  referer,
  session,
  runningUserExperiments,
  runningExperiments,
  seoTags
}) => {
  return (
    <ProviderComposer
      contexts={[
        ...[
          <LoadingProvider />,
          <MessageProvider />,
          <UserProvider user={user} session={session} />,
          <ExperimentProvider
            runningUserExperiments={runningUserExperiments}
            runningExperiments={runningExperiments}
          />,
          <CurrencyContext.Provider value={currency} />,
          <OrderProvider initialData={order} />,
          <WishlistProvider />
        ],
        ...(isMhubEnabled(store?.domainName)
          ? [
              <MhubProvider
                ip={ip}
                serverTime={serverTime}
                referer={referer}
                seoTags={seoTags}
                domainName={store?.domainName}
              />
            ]
          : []),
        ...[
          <OrderDispatchProvider ip={ip} />,
          <WishlistDispatchProvider />,
          <MainNavigationProvider value={navigationItem} />,
          <PremiumManufacturersNavigationProvider
            premiumManufacturers={premiumManufacturers}
          />,
          <CurrentPageProvider.Provider value={currentPathName} />,
          <ExternalRoutesProvider />,
          <LuigiTrackerProvider store={store} />,
          <FacebookProvider />,
          <AuthProvider />,
          <AdditionalInfoProvider />,
          <OverlayProvider />
        ]
      ]}
    >
      {children}
    </ProviderComposer>
  )
}

export default RootProvider
