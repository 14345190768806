import { FC } from 'react'
import { SvgProps } from './svgProps'

const ArrowIconBreadcrumb: FC<SvgProps> = ({ color }) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19L8 12L15 5"
      stroke={color || '#757575'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowIconBreadcrumb
