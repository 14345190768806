import { FC } from 'react'
import { SvgProps } from './svgProps'

const CartIconNew: FC<SvgProps> = ({ width, height, color, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 20}
    height={height || 20}
    viewBox="0 0 24 24"
    {...rest}
  >
    <path
      d="M22.5353 21.8291L21.7028 6.73176H17.5435V5.51292C17.5435 2.47296 15.0705 0 12.0307 0C8.99082 0 6.51786 2.47308 6.51786 5.51292V6.73176H2.17855L1.46227 21.8944L1.46154 21.9206C1.45566 22.4663 1.6725 23.0018 2.05614 23.3893C2.4399 23.7773 2.97306 24 3.51894 24H20.4811C21.0436 24 21.5884 23.7656 21.9754 23.3568C22.3623 22.948 22.5664 22.3912 22.5353 21.8291ZM7.95571 5.51292C7.95571 3.26592 9.78366 1.43784 12.0307 1.43784C14.2775 1.43784 16.1056 3.26592 16.1056 5.51292V6.73176H7.95558V5.51292H7.95571ZM20.9311 22.3682C20.8143 22.4914 20.6504 22.562 20.4809 22.562H3.51883C3.35443 22.562 3.19398 22.4951 3.07818 22.378C2.96514 22.264 2.90023 22.1071 2.89915 21.9463L3.5499 8.1696H6.51763V9.99036C6.51763 10.3874 6.83946 10.7093 7.23654 10.7093C7.63362 10.7093 7.95547 10.3874 7.95547 9.99036V8.1696H16.1055V9.99036C16.1055 10.3874 16.4273 10.7093 16.8244 10.7093C17.2215 10.7093 17.5432 10.3874 17.5432 9.99036V8.1696H20.3417L21.0993 21.9084C21.109 22.0776 21.0476 22.2452 20.9311 22.3682Z"
      stroke={color || 'black'}
      strokeWidth="0.4"
      fill={color || 'black'}
    />
  </svg>
)

export default CartIconNew
