import { FC, useState } from 'react'
import styled, { withTheme } from 'styled-components'
import { Box, Flex } from '@/components/atoms/Grid'
import MobileHeaderButton from '@/components/molecules/mobileHeaderButton'
import { IconButton } from '@/components/molecules/iconButtons'
import { validFor } from '@/theme/validFor'
import {
  ToolbarProps,
  TabletOrMobileToolbarProps
} from '@/components/organisms/toolbar/toolbar'
import ToolbarLinkLogo from '@/components/molecules/toolbarLinkLogo'
import { ITheme } from '@/theme/interfaces/ITheme'
import { Routes } from '@/core/routes'
import { FocusMode, useCurrentPage } from '@/providers/focusModeProvider'
import LoginButton from '@/components/molecules/loginButton'
import { useOrder } from '@/providers/order/old/orderProvider'
import { ReactHeight } from 'react-height'
import { MenuDrawerDefault } from '@/components/atoms/icons/MenuDrawerDefault'
import { WishlistIconDefault } from '@/components/atoms/icons/WishlistIconDefault'
import SwiperMenuAdd from '../../swiperMenuAdd'
import { useAdditionalInfoProvider } from '@/providers/additionalInfoProvider'
import SlideMenuAdditionalInfo from '../../slideMenuAdditionalInfo'
import { Asset, useAsset } from '@/providers/assetsProvider'
import { ChristmasGiftBoxIconDefault } from '@/components/atoms/icons/ChristmasGiftBoxIconDefault'
import { useScrollCondition } from '../../../../hooks/useScroll'
import { CartIconNewDefault } from '@/components/atoms/icons/CartIconNewDefault'
import { useOverlay } from '@/providers/showOverlayNewProvider'
import ProductSearchNew from '@/components/molecules/productSearchNew'

type MobileToolbarProps = ToolbarProps &
  TabletOrMobileToolbarProps & { theme?: ITheme }

const MobileToolbarWrapper: FC<MobileToolbarProps> = ({
  showHeaderPromo,
  headerNotice,
  isSearchbarOpen,
  ...rest
}) => {
  const [toolbarHeight, setToolbarHeight] = useState<number>(0)
  const offset = 50
  const isSticky = useScrollCondition((scroll) => scroll > offset)
  const isHeaderNoticeEnabled = headerNotice?.text.length > 0

  return (
    <Wrapper toolbarHeight={toolbarHeight} isSticky={isSticky}>
      <ReactHeight onHeightReady={(height) => setToolbarHeight(height)}>
        <MobileToolbarNew
          {...rest}
          showHeaderPromo={showHeaderPromo}
          headerNotice={headerNotice}
          isHeaderNoticeEnabled={isHeaderNoticeEnabled}
          isSearchbarOpen={isSearchbarOpen}
        />
      </ReactHeight>
    </Wrapper>
  )
}

const Wrapper = styled(Box)`
  height: ${(props) => props.toolbarHeight}px;
  position: relative;
  z-index: 12;
`

const MobileToolbarNew: FC<MobileToolbarProps> = ({
  theme,
  onDrawerClick,
  wishlistProductCount,
  showHeaderPromo,
  isHeaderNoticeEnabled
}) => {
  const { getFocusType } = useCurrentPage()
  const { count } = useOrder()
  const display = getFocusType() === FocusMode.Basic ? 'none' : 'inherit'
  const { show, showAdditionalMenu, showAdditionalMenuOnHP } =
    useAdditionalInfoProvider()
  const { isChristmasPromoTime, isShutDownCountry, isCartProcessStep } =
    useAsset()

  const isSearchbarOpen = !isCartProcessStep

  const { showOverlayNew, setShowOverlayNew } = useOverlay()

  return (
    <StyledFlex pl={12} pr={10} isSearchbarOpen={isSearchbarOpen}>
      {!showOverlayNew && (
        <>
          <Flex flexDirection="row">
            <StyledIconButton
              onClick={onDrawerClick}
              title={''}
              IconElement={
                <MenuDrawerDefault
                  color={theme.colors.onBackground}
                  data-cy="mobileHeaderToolbar"
                />
              }
              display={display}
            />
            <ToolbarLinkLogo
              imgAsset={Asset.InvertedLogo}
              ml={display ? 0 : 20}
              data-cy="headerMobileLogo"
            />
          </Flex>
          <Flex
            flexDirection="row"
            alignItems="center"
            height={29}
            data-cy="mobileHeaderButtons"
          >
            <LoginButton color={theme.colors.onBackground} />
            {!isShutDownCountry && (
              <>
                <MobileHeaderButton
                  data-cy={Routes.wishlist}
                  hrefLink={Routes.wishlist}
                  IconElement={
                    isChristmasPromoTime ? (
                      <ChristmasGiftBoxIconDefault
                        color={theme.colors.onBackground}
                      />
                    ) : (
                      <WishlistIconDefault color={theme.colors.onBackground} />
                    )
                  }
                  notifyCount={wishlistProductCount}
                  display={display}
                />

                <MobileHeaderButton
                  data-cy={Routes.cart}
                  hrefLink={Routes.cart}
                  IconElement={
                    <CartIconNewDefault color={theme.colors.onBackground} />
                  }
                  notifyCount={count}
                />
                {show && <SlideMenuAdditionalInfo />}
                {showAdditionalMenu || showAdditionalMenuOnHP ? (
                  <SwiperMenuAdd color={theme.colors.onBackground} />
                ) : null}
              </>
            )}
          </Flex>
        </>
      )}

      <MobileSearchWrapper
        isHeaderNoticeEnabled={isHeaderNoticeEnabled}
        showHeaderPromo={showHeaderPromo}
        isSearchbarOpen={isSearchbarOpen}
        showOverlayNew={showOverlayNew}
      >
        <ProductSearchNew isSearchbarOpen={isSearchbarOpen} />
      </MobileSearchWrapper>
    </StyledFlex>
  )
}

export default withTheme(MobileToolbarWrapper)

const StyledFlex = styled(Flex)`
  background-color: ${(props) => props.theme.colors.menuBackground};
  flex-direction: row;
  justify-content: space-between;
  ${validFor.tablet` 
    background-color: ${(props) => props.theme.colors.background};
  `}
  align-items: center;
  padding-left: 20px;
  padding-right: 15px;
  padding-bottom: ${(props) => props.isSearchbarOpen && '53px'};
  height: 60px;
  transition: all 0.2s ease-out;
  box-shadow: 0px 2px 4px rgba(220, 220, 220, 0.5);
`

const MobileSearchWrapper = styled.div`
  justify-content: stretch;
  position: absolute;
  top: ${(props) =>
    props.showOverlayNew ? '20px' : props.isSearchbarOpen ? '56px' : 'initial'};
  left: 0;
  right: 0;
  display: ${(props) =>
    props.show || props.isSearchbarOpen ? 'flex' : 'none'};
`
const StyledIconButton = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.colors.background};
`
