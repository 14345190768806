import { FC } from 'react'
import Badge from '@/components/atoms/badge'
import Link from '@/components/atoms/link'
import { Flex } from '@/components/atoms/Grid'
import styled from 'styled-components'
import {
  BreadcrumbDetailMobileNewVariant,
  ExperimentType,
  useVariant
} from '@/providers/experimentProvider'

import { validFor } from '@/theme/validFor'

type MobileHeaderButton = {
  hrefLink: string
  notifyCount?: number
  IconElement: JSX.Element
} & Rest

const MobileHeaderButton: FC<MobileHeaderButton> = ({
  hrefLink,
  notifyCount,
  IconElement,
  ...rest
}) => {
  const switchVariantBreadcrumb = useVariant(
    ExperimentType.BreadcrumbDetailMobileNew
  )

  const useBreadcrumbDetailMobileNew =
    switchVariantBreadcrumb === BreadcrumbDetailMobileNewVariant.VariantA

  return (
    <Link href={hrefLink}>
      <StyledFlex {...rest}>
        {IconElement}
        {notifyCount && notifyCount > 0 ? (
          <StyledBadge
            useBreadcrumbDetailMobileNew={useBreadcrumbDetailMobileNew}
            counter={notifyCount}
          />
        ) : null}
      </StyledFlex>
    </Link>
  )
}
export default MobileHeaderButton

const StyledFlex = styled(Flex)`
  flex-direction: column;
  margin: 10px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`

const StyledBadge = styled(Badge)`
  position: absolute;
  left: 50%;
  bottom: 50%;

  ${(props) =>
    validFor.mobile &&
    props.useBreadcrumbDetailMobileNew &&
    `
      font-size: 12px;
      min-width: 16px;
      height: 16px;
      border: 1px solid ${props.theme.colors.accent};
    `}
`
