import React, { createContext, useContext, useState } from 'react'

type OverlayContextType = {
  showOverlayNew: boolean
  setShowOverlayNew: React.Dispatch<React.SetStateAction<boolean>>
}

const OverlayContext = createContext<OverlayContextType | undefined>(undefined)

export const OverlayProvider: React.FC = ({ children }) => {
  const [showOverlayNew, setShowOverlayNew] = useState<boolean>(false)

  return (
    <OverlayContext.Provider value={{ showOverlayNew, setShowOverlayNew }}>
      {children}
    </OverlayContext.Provider>
  )
}

export const useOverlay = (): OverlayContextType => {
  const context = useContext(OverlayContext)
  if (!context) {
    throw new Error('useOverlay must be used within an OverlayProvider')
  }
  return context
}
